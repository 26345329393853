<template>
	<div class="choice-list">
		<div class="title">{{ totalText }}</div>
		<div v-if="upcomingChoices.length != 0">
			<div class="groupHeader">Upcoming</div>
			<div class="groupChoices">
				<choice-row
					:choice="choice"
					v-for="choice in upcomingChoices"
					v-bind:key="'choice:' + refresher+':' +choice.id"
				></choice-row>
			</div>
		</div>
		<div v-if="pendingChoices.length != 0">
			<div class="groupHeader">Active</div>
			<div class="groupChoices middle">
				<choice-row
					:choice="choice"
					:refresher="refresher"
					v-for="choice in pendingChoices"
					v-bind:key="'choice:' + refresher+':' +choice.id"
				></choice-row>
			</div>
		</div>
		<div class="bottom-spacer" />
	</div>
</template>

<script>
import ChoiceRow from "@/components/ChoiceRow";
import moment from 'moment';

export default {
	name: "ChoiceList",
	components: {
		ChoiceRow
	},
	data() {
		return {
			refresher: 0,
			allChoices: [],
			upcomingChoices: [],
			pendingChoices: [],
			totalText: "Loading...."
		};
	},
	created() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.allChoices = JSON.parse(localStorage.getItem('choice-list'));
			this.parseChoices();
			this.allChoices = await this.$services.getAll(`choices`);
			console.log("Choices are", this.allChoices);
			localStorage.setItem('choice-list', JSON.stringify(this.allChoices));
			this.totalText = `${this.allChoices.length} Current Choices`;
			this.parseChoices();
		},
		parseChoices: async function () {
			this.upcomingChoices = this.allChoices.filter((choice) => {
				return moment(choice.cutoffTime) > moment();
			});
			this.upcomingChoices.sort((a, b) => {
				if (a.expectation.id == null && b.expectation.id != null) return -1;
				if (b.expectation.id == null && a.expectation.id != null) return 1;
				if (a.cutoffTime > b.cutoffTime) return 1;
				if (a.cutoffTime < b.cutoffTime) return -1;
				return a.name > b.name ? 1 : -1;
			});
			this.pendingChoices = this.allChoices.filter((choice) => {
				return !choice.settled && moment(choice.cutoffTime) < moment();
			});
			this.pendingChoices.sort((a, b) => {
				return a.resultTime < b.resultTime ? 1 : -1;
			});
			this.refresher++;
		}
	}
}

</script>

<style scoped>
.groupHeader {
	font-size: 22px;
	background: #444444;
	margin: 5px 0px 2px 0px;
	border-top: 1px solid yellow;
	color: white;
	padding: 2px;
}
.middle {
	background-color: #202020;
}
</style>
